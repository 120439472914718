* {
  font-family: "poppins" !important;
}

body {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0003;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0006;
}
